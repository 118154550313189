<template>
  <v-fade-transition mode="out-in">
    <div class="wbs-detail">
      <div class="wbs-detail-loading" v-if="isPageLoading">
        <v-progress-circular
          :width="5"
          color="grey"
          indeterminate
          :size="30"
          ></v-progress-circular>
        <div
          class="grey--text"
          :style="{
            'margin-top': '12px',
            'font-size': '14px'
          }"
          >
          Loading
        </div>
      </div>
      <v-layout wrap v-else>
        <v-flex
          xs12
          lg8
          order-xs2
          pt-0
          pb-0
          >
          <material-app-card
            id="wbs-form"
            :color="section_color"
            icon="mdi-text-box-check-outline"
            title="CI WBS Request Form"
            >
            <v-form ref="wbs-info-form" class="px-1 pb-1">
              <v-layout row wrap class="v-card-x-layout">
                <v-flex xs12 pt-3 pb-6 pl-2 pr-2 class="v-card-x-cell">
                  <div 
                    class="ml-2 wbs--text"
                    style="font-size:17px;font-weight:400;"
                    >
                    Opportunity Information
                  </div>
                </v-flex>
                <v-flex xs12 pt-0 pl-3 pr-3 pb-4 class="v-card-x-cell" style="margin-top:-12px!important"
                  v-if="error.opp.filter(el=>el.valid).length"
                  >
                  <v-alert
                    :value="true"
                    type="error"
                    class="mb-0"
                    >
                    <ul>
                      <template v-for="(ao, ai) in error.opp">
                        <li v-if="ao.valid" :key="'project_alert_' + ai" class="text-body-2">
                          {{ ao.text }}
                        </li>
                      </template>
                    </ul>
                  </v-alert>
                </v-flex>
                <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell">
                  <v-text-field
                    label="Opportunity ID *"
                    placeholder="Please input Opportunity ID"
                    v-model="inputOppId"
                    :disabled="!isWbsUserEditable"
                    :append-icon="isWbsUserEditable && inputOppId ? 'mdi-magnify' : ''"
                    :loading="isOppLoading"
                    @change="changeSearch"
                    @focus="focusSearch"
                    @blur="blurSearch"
                    @click:append="searchOpp"
                    ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell">
                  <v-text-field
                    disabled
                    label="Opportunity Name"
                    placeholder="-"
                    :value="wbs.opp.name"
                    ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell">
                  <v-text-field
                    disabled
                    label="Account Name"
                    placeholder="-"
                    :value="wbs.opp.accName"
                    ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell">
                  <v-text-field
                    disabled
                    label="Sold To Party"
                    placeholder="-"
                    :value="wbs.opp.sap"
                    ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell">
                  <v-text-field
                    disabled
                    label="Stage"
                    placeholder="-"
                    :value="wbsOppStageAndProbText"
                    ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell">
                  <v-text-field
                    disabled
                    label="Contract Revenue Amount/ Currency"
                    placeholder="-"
                    :value="(!wbs.opp.amount && !wbs.opp.currency) ? '-' : toThousandsInt(wbs.opp.amount) + ' ' + wbs.opp.currency"
                    ></v-text-field>
                </v-flex>

                <v-flex xs12 pt-2 pb-3 pl-2 pr-2 class="v-card-x-cell">
                  <div 
                    class="ml-2 wbs--text"
                    style="font-size:17px;font-weight:400;"
                    >
                    CTR Information
                  </div>
                </v-flex>
                <v-flex xs12 pt-0 pl-3 pr-3 pb-4 class="v-card-x-cell" style="margin-top:-12px!important"
                  v-if="error.project.filter(el=>el.valid).length"
                  >
                  <v-alert
                    :value="true"
                    type="error"
                    class="mt-2 mb-0"
                    >
                    <ul>
                      <template v-for="(ap, ai) in error.project">
                        <li v-if="ap.valid" :key="'project_alert_' + ai" class="text-body-2">
                          {{ ap.text }}
                        </li>
                      </template>
                    </ul>
                  </v-alert>
                </v-flex>
                <v-flex xs12 pt-1 pl-3 pr-3 pb-3 class="v-card-x-cell">
                  <v-data-table
                    :headers="c2rHeaders"
                    :items="wbsProjInTable"
                    :items-per-page="-1"
                    hide-default-footer>
                    <template v-slot:item="props">
                      <tr>
                        <template v-if="props.item.total">
                          <td style="font-weight:500;" colspan="2">Total</td>
                          <td style="font-weight:500;text-align:right;">{{ props.item.c2r || '-' }}</td>
                          <td></td>
                          <td style="font-weight:500;text-align:right;">{{ toThousandsInt(props.item.amount) }} {{ props.item.currency }}</td>
                        </template>
                        <template v-else>
                          <td 
                            :style="(!props.item.c2r || !props.item.c2r.per) ? { 'color': 'red' } : {}">
                            {{ props.item.id }}
                          </td>
                          <td
                            :style="(!props.item.c2r || !props.item.c2r.per) ? { 'color': 'red' } : {}">
                            {{ props.item.ql_o_qt || '-' }}
                          </td>
                          <td 
                            :style="{ 'text-align': 'right', ...((!props.item.c2r || !props.item.c2r.per) ? { 'color': 'red' } : {}) }">
                            {{ props.item.c2r.per || '-' }}
                          </td>
                          <td
                            :style="(!props.item.c2r || !props.item.c2r.per) ? { 'color': 'red' } : {}">
                            {{ props.item.sDate }} - {{ props.item.eDate }}
                          </td>
                          <td 
                            :style="{ 'text-align': 'right', ...((!props.item.c2r || !props.item.c2r.per) ? { 'color': 'red' } : {}) }">
                            {{ toThousandsInt(props.item.amount) }} {{ props.item.currency }}
                          </td>
                        </template>
                      </tr>
                    </template>
                    <template v-slot:no-data>
                      <div style="width:100%;text-align:center">No Project</div>
                    </template>
                  </v-data-table>
                </v-flex>
                
                <v-flex xs12 pt-3 pb-3 pl-2 pr-2 class="v-card-x-cell">
                  <div 
                    class="ml-2 wbs--text"
                    style="font-size:17px;font-weight:400;"
                    >
                    Others
                  </div>
                </v-flex>
                <v-flex xs12 pt-0 pl-3 pr-3 pb-4 class="v-card-x-cell" style="margin-top:-12px!important"
                  v-if="error.other.filter(el=>el.valid).length"
                  >
                  <v-alert
                    :value="true"
                    type="error"
                    class="mt-2 mb-0"
                    >
                    <ul>
                      <template v-for="(aot, ai) in error.other">
                        <li v-if="aot.valid" :key="'project_alert_' + ai" class="text-body-2">
                          {{ aot.text }}
                        </li>
                      </template>
                    </ul>
                  </v-alert>
                </v-flex>
                <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell">
                  <v-select
                    v-model="wbs.other.industry"
                    label="Industry *"
                    placeholder="Please select"
                    ref="wbs_form_type"
                    :disabled="!isWbsUserEditable"
                    :items="industriesInSelect"
                    ></v-select>
                </v-flex>
                <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell">
                  <div>
                    <v-dialog
                      ref="dateCtrtBack"
                      v-model="isShowingDateCtrtBack"
                      :return-value.sync="wbs.other.dateCtrtBack"
                      persistent
                      width="300px"
                      >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          readonly
                          label="Estimated Contract Back Date *"
                          placeholder="Please select"
                          append-icon="mdi-calendar"
                          ref="wbs_form_date_ctrt_back"
                          v-on="on"
                          v-model="wbs.other.dateCtrtBack"
                          :disabled="!isWbsUserEditable"
                        ></v-text-field>
                      </template>
                      <v-date-picker :disabled="!isWbsUserEditable" v-model="wbs.other.dateCtrtBack" scrollable :color="section_color" header-:color="section_color">
                        <v-spacer></v-spacer>
                        <v-btn text :color="section_color" class="mb-2" @click="isShowingDateCtrtBack = false">CANCEL</v-btn>
                        <v-btn dark :color="section_color" class="mb-2" @click="$refs.dateCtrtBack.save(wbs.other.dateCtrtBack)">OK</v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </div>
                </v-flex>
                <v-flex xs12 pt-3 pl-3 pr-3 pb-1 class="v-card-x-cell">
                  <div :class="['v-card-x-cell-title']">Business Justifications *</div>
                  <v-textarea
                    solo
                    no-resize
                    rows="3"
                    placeholder="Please specify"
                    style="margin-top: 0px"
                    ref="contract_form_busi_just"
                    v-model="wbs.other.busiJust"
                    :counter="busiJustMax"
                    :readonly="!isWbsUserEditable"
                    ></v-textarea>
                </v-flex>
                <v-flex xs12 pt-1 pl-3 pr-3 pb-3 class="v-card-x-cell">
                  <div :class="['v-card-x-cell-title']">EoA Confirmation (PDF) *</div>
                  <div v-if="isWbsUserEditable" style="display:flex;align-items:center;">
                    <x-upload
                      request-class="file"
                      request-function="upload"
                      accept=".pdf"
                      :params="{
                        email: userEmail,
                        loginStatus: loginStatus
                      }"
                      :before-upload="beforeUploadEoA"
                      :on-error="errorUploadEoA"
                      :on-success="successUploadEoA"
                      >
                      <v-btn text
                        style="margin: 0!important;padding:10px 9px 10px 3px!important;"
                        >
                        <v-icon :color="section_color" size="24" style="margin-right:12px;">
                          mdi-upload
                        </v-icon>
                        <div style="font-size:12px;">Please upload <b>EoA (PDF)</b></div>
                      </v-btn>
                    </x-upload>
                    <v-progress-circular
                      v-if="cntFileUploading"
                      class="ml-3"
                      color="grey"
                      indeterminate
                      :width="3"
                      :size="18"
                      ></v-progress-circular>
                  </div>
                  <div v-else class="mb-2"></div>
                  <file-list
                    :files="wbs.eoas"
                    :deletable="isWbsUserEditable"
                    :multiple-rows="windowWidth < 650"
                    :btn-color="section_color"
                    @click-download="downloadFile"
                    @click-delete="deleteEoA"
                    ></file-list>
                </v-flex>
                <v-flex xs12 pt-5 pl-3 pr-3 pb-0 class="v-card-x-cell" style="margin-top:-12px!important"
                  v-if="error.project.filter(el=>el.valid).length > 0 || error.opp.filter(el=>el.valid).length > 0 || error.other.filter(el=>el.valid).length > 0"
                  >
                  <v-alert
                    :value="true"
                    type="error"
                    class="mt-2 mb-0 text-body-2">
                    WBS request form incomplete. Please check alert(s) above.
                  </v-alert>
                </v-flex>
                <v-flex xs6 pt-1 pl-3 pr-3 pb-3 class="v-card-x-cell">
                  <div class="bottom-btn-container">
                    <v-btn v-if="isWbsRecalled || isWbsRejected" class="theme--dark" color="error"
                      :loading="isAbandoning"
                      @click="isAbandoning = true">
                      Abandon
                    </v-btn>
                  </div>
                </v-flex>
                <v-flex xs6 pt-1 pl-3 pr-3 pb-3 class="v-card-x-cell">
                  <div v-if="isWbsUserEditable" class="bottom-btn-container-right">
                    <v-btn 
                      :disabled="isPageLoading"
                      :loading="isSubmitting || isOppLoading || isProjLoading"
                      class="theme--dark"
                      :color="section_color"
                      @click="startSubmit">
                      Submit
                    </v-btn>
                  </div>
                </v-flex>
              </v-layout>
            </v-form>
          </material-app-card>
        </v-flex>

        <v-flex
          xs12
          lg4
          order-lg2
          pt-0
          pb-0
        >
          <material-app-card
            icon="mdi-radar"
            :color="section_color"
            :title="isNewWbs ? 'CI WBS Request' : wbs.reqid"
          >
            <template v-slot:extra>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn small text icon dark :color="section_color"
                    v-if="canRecall"
                    @click="isRecalling = true"
                    v-on="on"
                  >
                    <v-icon>mdi-restart</v-icon>
                  </v-btn>
                </template>
                <span>Recall</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn small text icon dark :color="section_color"
                    v-if="!isNewWbs"
                    @click="startShareAccess"
                    v-on="on"
                  >
                    <v-icon v-if="canShare">mdi-account-plus</v-icon>
                    <v-icon v-else>mdi-account</v-icon>
                  </v-btn>
                </template>
                <span v-if="canShare">Share Access</span>
                <span v-else>Access</span>
              </v-tooltip>
            </template>
            <template v-slot>
              <div class="wbs-stepper">
                <v-stepper
                  :value="stageStep"
                  vertical
                >
                  <template v-for="(stage, si) in stageListStepper">
                    <!-- <v-divider
                      :key="'step_divider_' + si"
                    ></v-divider> -->
                    <v-stepper-step
                      :key="'step_' + si"
                      :color="section_color"
                      :step="si"
                      :complete="stageStep >= si"
                      :complete-icon="stageStep > si ? '$vuetify.icons.complete' : 'mdi-star'"
                    >
                      {{ stage.value }}
                    </v-stepper-step>
                    <v-stepper-content :key="'step_content_' + si" :step="si + 1">
                    </v-stepper-content>
                  </template>
                </v-stepper>
              </div>
            </template>
          </material-app-card>

          <material-app-card
            v-if="isWbsInAnyApproval || isWbsApproved"
            :color="section_color"
            icon="mdi-message-draw"
            title="Review Panel"
          >
            <template v-slot:extra>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn small text icon dark :color="section_color"
                    v-if="isWbsInAnyApproval && isApprover"
                    @click="isForwarding = true"
                    v-on="on"
                  >
                    <v-icon>mdi-share</v-icon>
                  </v-btn>
                </template>
                <span>Forward</span>
              </v-tooltip>
            </template>
            <template v-slot>
              <v-layout row wrap class="v-card-x-layout">
                <v-flex xs12 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell v-card-x-cell"
                  v-if="isWbsCodeShown"
                  >
                  <div style="display:flex;align-items: center">
                    <wbs-tooltip-intro />
                    <v-text-field
                      label="WBS Parent"
                      :placeholder="isEditor ? 'Please assign WBS Parent' : ' '"
                      v-model="wbs.code"
                      disabled
                      hide-details
                      ></v-text-field>
                  </div>
                </v-flex>
                <v-flex xs12 pt-3 pl-3 pr-3 pb-3 class="v-card-x-cell v-card-x-cell">
                  <div :class="['v-card-x-cell-title']">EoA:</div>
                  <file-list
                    :files="wbs.eoas"
                    :deletable="false"
                    :btn-color="section_color"
                    :multiple-rows="$vuetify.breakpoint.lgAndUp || !$vuetify.breakpoint.smAndUp"
                    @click-download="downloadFile"
                    ></file-list>
                </v-flex>
                <v-flex xs12 class="v-flex-row-breaker">
                </v-flex>
                <template v-if="!isPageLoading">
                  <v-flex xs6 pt-1 pl-3 pr-3 pb-3>
                    <div class="bottom-btn-container">
                      <v-btn v-if="isWbsUserCommentable" class="theme--dark" :color="section_color"
                        @click="startComments">
                        Comment
                      </v-btn>
                    </div>
                  </v-flex>
                  <v-flex xs6 pt-1 pl-3 pr-3 pb-3>
                    <div class="bottom-btn-container-right">
                      <div style="width:fit-content;float:right;">
                        <v-speed-dial
                          v-if="isWbsUserApprovable || isWbsUserRejectable" 
                          v-model="isActionsBtnShown"
                          direction="bottom"
                          transition="slide-y-transition"
                        >
                          <template v-slot:activator>
                            <v-btn 
                              class="theme--dark" 
                              :color="section_color"
                              width="108px"
                              >
                              <v-icon v-if="isActionsBtnShown">mdi-chevron-up</v-icon>
                              <div v-else>Action</div>
                            </v-btn>
                          </template>
                          <div class="my-n4">
                            <v-btn 
                              v-if="isWbsUserApprovable" 
                              class="theme--dark my-1" 
                              :color="section_color"
                              width="108px"
                              @click="isApprovalConfirming = true"
                              >
                              Approve
                            </v-btn>
                            <v-btn 
                              v-if="isWbsUserRejectable" 
                              class="theme--dark my-1" 
                              :color="section_color"
                              width="108px"
                              @click="isApprovalRejecting = true"
                              >
                              Reject
                            </v-btn>
                          </div>
                        </v-speed-dial>
                        <v-btn 
                          v-else-if="isWbsCodeUserEditable" 
                          block 
                          class="theme--dark" 
                          :color="section_color"
                          @click="isWbsCodeEditing = true"
                          >
                          Assign WBS
                        </v-btn>
                      </div>
                    </div>
                  </v-flex>
                </template>
              </v-layout>
            </template>
          </material-app-card>

          <material-app-card
            :color="section_color"
            icon="mdi-history"
            title="Activity History"
            v-if="history.length > 0 && $vuetify.breakpoint.lgAndUp"
          >
            <template v-slot>
              <v-layout row wrap class="v-card-x-layout">
                <v-flex xs12 pl-3 pr-3 pb-3 class="v-card-x-cell v-card-x-cell">
                  <activity-history
                    :spot-color="section_color"
                    :btn-color="section_color"
                    :history="history"
                  >
                  </activity-history>
                </v-flex>
              </v-layout>
            </template>
          </material-app-card>
        </v-flex>

        <v-flex
          v-if="!$vuetify.breakpoint.lgAndUp"
          order-xs3
          xs12
          pt-0
          pb-0
        >
          <material-app-card
            :color="section_color"
            icon="mdi-history"
            title="Activity History"
            v-if="history.length > 0"
          >
            <template v-slot>
              <v-layout row wrap class="v-card-x-layout">
                <v-flex xs12 pl-3 pr-3 pb-3 class="v-card-x-cell v-card-x-cell">
                  <activity-history
                    :history="history"
                  >
                  </activity-history>
                </v-flex>
              </v-layout>
            </template>
          </material-app-card>
        </v-flex>

        <confirm-dialog
          :card-style="{
            width: '300px'
          }"
          v-model="isRecalling"
          title="Recall"
          comments="Do you confirm to recall this request? (Once you recalled, approval process will restart from the very beginning)"
          :btn-color="section_color"
          @click-yes="recall"
        ></confirm-dialog>

        <confirm-dialog
          :card-class="['confirm-card']"
          v-model="isAbandoning"
          title="Abandon"
          comments="Do you confirm to abandon this WBS request?"
          :btn-color="section_color"
          @click-yes="abandon"
        ></confirm-dialog>
        
        <share-access
          v-model="isAccessSharing"
          :owner="requestor"
          :members="collaborators"
          :editable="canShare"
          :btn-color="section_color"
          @save-members="updateAccess"
        ></share-access>

        <forward-reviewer
          v-if="isApprover"
          v-model="isForwarding"
          :btn-color="section_color"
          @complete="forward"
        ></forward-reviewer>

        <v-dialog
          v-model="isApprovalConfirming"
          persistent
          width="fit-content"
        >
          <v-card class="approve-card">
            <v-list class="white">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title :style="{'font-size': '20px'}">Approve</v-list-item-title>
                </v-list-item-content>

                <v-spacer></v-spacer>

                <v-list-item-action>
                  <v-btn text icon @click="isApprovalConfirming = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>

            <div style="position: absolute;top: 64px;bottom: 0;left: 0;right: 0;overflow: auto;">
              <div style="text-align: left;padding: 3px 16px;">
                Please confirm the approval of this request.
              </div>
              <div
                class="wbs-review-comments-container"
                :style="{ 'padding': '12px 16px' }"
              >
                <v-textarea
                  v-model="comments.pop.approval"
                  placeholder="Please input comments (if any)"
                  :row="3"
                  hide-details
                  solo
                  no-resize
                ></v-textarea>
              </div>

              <div style="padding: 0 16px 2px;text-align: right;">
                <v-btn dark :color="section_color" @click="approve">
                  Approve
                </v-btn>
              </div>
            </div>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="isApprovalRejecting"
          persistent
          width="fit-content"
        >
          <v-card class="approve-card">
            <v-list class="white">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title :style="{'font-size': '20px'}">Reject</v-list-item-title>
                </v-list-item-content>

                <v-spacer></v-spacer>

                <v-list-item-action>
                  <v-btn text icon @click="isApprovalRejecting = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>

            <div style="position: absolute;top: 64px;bottom: 0;left: 0;right: 0;overflow: auto;">
              <div style="text-align: left;padding: 3px 16px;">
                Please confirm the rejection of this request.
              </div>
              <div
                class="wbs-review-comments-container"
                :style="{ 'padding': '12px 16px' }"
              >
                <v-textarea
                  v-model="comments.pop.rejection"
                  placeholder="Please input comments (if any)"
                  :row="3"
                  hide-details
                  solo
                  no-resize
                ></v-textarea>
              </div>

              <div style="padding: 0 16px 2px;text-align: right;">
                <v-btn dark :color="section_color" @click="reject">
                  Reject
                </v-btn>
              </div>
            </div>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="isWbsCodeEditing"
          persistent
          width="fit-content"
        >
          <v-card class="wbs-code-card">
            <v-list class="white">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title :style="{'font-size': '18px'}">Please assign WBS Parent</v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn text icon @click="isWbsCodeEditing = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>

            <div style="position: absolute;top: 64px;bottom: 0;left: 0;right: 0;overflow: auto;">
              <div
                class="wbs-review-comments-container"
                :style="{ 'padding': '12px 16px' }"
              >
                <v-text-field
                  label=" "
                  :placeholder="' '"
                  v-model="inputWbsCode"
                  ></v-text-field>
              </div>

              <div style="padding: 0 16px 2px;text-align: right;">
                <v-btn 
                  :color="section_color" 
                  :dark="!!inputWbsCode && inputWbsCode != wbs.code"
                  :disabled="!inputWbsCode || inputWbsCode == wbs.code"
                  @click="saveWbsCode">
                  Save
                </v-btn>
              </div>
            </div>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="isCommenting"
          width="fit-content"
          persistent
          :fullscreen="isMobile"
          :hide-overlay="isMobile"
          :transition="isMobile ? 'dialog-bottom-transition' : 'dialog-transition'"
        >
          <v-card class="comments-card">
            <v-list :class="isMobile ? ['white', 'sidebar-top'] : ['white']">
              <v-list-item>
                <template v-if="isMobile">
                  <v-list-item-action>
                    <v-btn v-if="isMobile" text icon @click="closeSendComments">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-list-item-action>

                  <v-spacer></v-spacer>
                </template>

                <v-list-item-content>
                  <v-list-item-title :style="isMobile ? {'text-align': 'center'} : {'font-size': '20px'}">
                    Comments
                  </v-list-item-title>
                </v-list-item-content>

                <v-spacer></v-spacer>

                <v-list-item-action>
                  <v-btn v-if="isMobile" text icon @click="sendComments">
                    <v-icon>check</v-icon>
                  </v-btn>
                  <v-btn v-else text icon @click="closeSendComments">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>

            <v-form ref="contract-comment-form">
              <div :style="{position: 'absolute',top: '64px',bottom: 0,left: 0,right: 0,overflow: 'auto'}">
                <div
                  class="contract-review-comments-container"
                  v-if="isApprover"
                  :style="isMobile ? { 'padding': '12px 15px 6px' } : { 'padding': '8px 16px 0' }"
                >
                  <person-select
                    v-model="comments.card.review.to"
                    label="Comment to"
                    :items="listApproverCommentTo"
                    :rules="[v => (!!v && !!listApproverCommentTo.find(el => el.email === v.email)) || 'Please select']"
                  >
                  </person-select>
                </div>

                <div
                  class="contract-review-comments-container"
                  :style="isMobile ? { 'padding-bottom': '6px' } : { 'padding': '8px 16px 0' }"
                >
                  <v-textarea
                    v-model="comments.card.review.content"
                    :rows="(isMobile ? commentBoxRows : 6) - (isApprover ? 3 : 0)"
                    :placeholder="`Please input comments`"
                    :counter="commentsMax"
                    :solo="!isMobile"
                    no-resize
                    :rules="[...([v => (!!v && v.toString().trim() != '') || 'Please input'])]"
                  ></v-textarea>
                </div>
                <div style="display: flex; align-items: center;justify-content: space-between;padding: 0 12px;">
                  <x-upload
                    request-class="file"
                    request-function="upload"
                    :params="{
                      email: userEmail,
                      loginStatus: loginStatus
                    }"
                    :before-upload="beforeUploadCmmtAtt"
                    :on-error="errorUploadCmmtAtt"
                    :on-success="successUploadCmmtAtt"
                  >
                    <v-btn text
                      style="margin: 0!important;padding:10px 12px 10px 6px!important;"
                    >
                      <v-icon dark :color="section_color" size="24" style="margin-right:12px;">
                        mdi-upload
                      </v-icon>
                      <div>Upload Supporting Documents (If Any)</div>
                    </v-btn>
                  </x-upload>
                </div>
                <file-list
                  :files="comments.card.review.files"
                  :btn-color="section_color"
                  :multiple-rows="windowWidth < 650"
                  :style="{
                    'height': 'calc(100% - ' + ((isMobile ? commentBoxRows * 18 + 24 : 244) + (isApprover ? 36 : 32) + 42) + 'px)',
                    'padding': '0 12px',
                    'padding-bottom': (isMobile ? 60 : 0) + 'px',
                    'overflow-y': 'auto'
                  }"
                  @click-download="downloadFile"
                  @click-delete="deleteAttachment"
                ></file-list>

                <div v-if="!isMobile" style="padding: 6px 16px;text-align: right;">
                  <v-btn dark :color="section_color"
                    @click="sendComments"
                  >
                    Send
                  </v-btn>
                </div>
              </div>
            </v-form>
          </v-card>
        </v-dialog>
      </v-layout>
    </div>
  </v-fade-transition>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import { section_color } from "@/config";
import activityHistory from '@/components/app/history'
import shareAccess from '@/components/app/dialog/share-access'
import forwardReviewer from '@/components/app/dialog/forward-reviewer'
import wbsTooltipIntro from '@/components/app/wbs/tooltip-intro'
import fileList from '@/components/vuetify-extension/file-list'
import confirmDialog from '@/components/vuetify-extension/confirm-dialog'
import personSelect from '@/components/vuetify-extension/person-select'
import xUpload from '@/components/x-upload'

import { xPost, xDownload } from '@/functions/http/axios'
import { toThousands, formatTime, to2Digits } from '@/functions/maths'

export default {
  components: {
    activityHistory,
    shareAccess,
    forwardReviewer,
    wbsTooltipIntro,
    fileList,
    confirmDialog,
    personSelect,
    xUpload
  },
  data () {
    return {
      // params
      isPageLoading: true,
      isOppLoading: false,
      isProjLoading: false,
      isShowingDateCtrtBack: false,
      isSubmitting: false,
      isAbandoning: false,
      isApprovalRejecting: false,
      isApprovalConfirming: false,
      isRecalling: false,
      isWbsCodeEditing: false,
      isWbsCodeSaving: false,
      isAccessSharing: false,
      isForwarding: false,
      isCommenting: false,
      isActionsBtnShown: false,
      isSearchFocused: false,

      busiJustMax: 1000,
      commentsMax: 1000,

      projSearchable: false,
      cntOpp: 0,
      cntProj: 0,
      cntFileUploading: 0,
      
      defaultRequestors: {
        name: 'Requestors',
        email: 'Owner & Members',
        icon: require('@/static/img/avatars/team.jpg')
      },

      // value / message
      inputOppId: null,
      inputProjId: null,
      inputWbsCode: null,
      error: {
        opp: [
          {
            key: 'NoID',
            valid: false,
            text: 'Opportunity ID is required!'
          },
          {
            key: 'NotFound',
            valid: false,
            text: 'Opportunity ID cannot be found!'
          },
          {
            key: 'MoreThan1',
            valid: false,
            text: 'More than one opportunities is found!'
          },
          {
            key: 'ErrStage',
            valid: false,
            text: 'Only Opportunity with Negotiating or above is eligible for WBS Approval!'
          }
        ],
        project: [
          {
            key: 'NotFound',
            valid: false,
            text: 'Project cannot be found!'
          },
          {
            key: 'CINotFound',
            valid: false,
            text: 'There is no CI project under this Opportunity ID! Please choose a proper one!'
          },
          {
            key: 'NoApproval',
            valid: false,
            text: 'Please get CTR Approval first!'
          },
          {
            key: 'NoTag',
            valid: false,
            text: 'Please add commission tag in CI Workbench!'
          },
          {
            key: 'NoApprovalTag',
            valid: false,
            text: "The highlight project's Commissioned CTR cannot be found，please go to CI Workbench to finish the process first!"
          }
        ],
        other: [
          {
            key: 'NoIndustry',
            valid: false,
            text: 'Industry is required!'
          },
          {
            key: 'NoDateCtrtBack',
            valid: false,
            text: 'Estimated Contract Back Date is required!'
          },
          {
            key: 'NoBusiJust',
            valid: false,
            text: 'Business Justifications is required!'
          },
          {
            key: 'NoEoA',
            valid: false,
            text: 'EoA is required!'
          }
        ]
      },

      // list
      c2rHeaders: [
        {
          text: 'Project ID',
          sortable: false,
          value: 'id',
          class: 'text-body-2 font-weight-bold',
        },
        {
          text: 'Quali/Quanti',
          sortable: false,
          value: 'ql_o_qt',
          class: 'text-body-2 font-weight-bold',
        },
        {
          text: 'Commisioned CTR',
          sortable: false,
          align: 'right',
          class: 'text-body-2 font-weight-bold',
        },
        {
          text: 'Start Date - End Date',
          sortable: false,
          value: 'stage',
          class: 'text-body-2 font-weight-bold',
        },
        {
          text: 'Project Amount',
          sortable: false,
          align: 'right',
          class: 'text-body-2 font-weight-bold',
        }
      ],
      industries: [],
      reviewers: [],

      // user
      roles: [],
      requestor: {
        email: null,
        name: null,
        icon: null
      },
      collaborators: [],

      // data
      wbs: {
        // request id
        reqid: null,
        code: null,

        // stage step
        stage: {
          code: null
        },

        // details
        //// opportunity
        opp: {
          id: null,
          name: null,
          accName: null,
          sap: null,
          stage: null,
          prob: null,
          amount: null,
          currency: null
        },
        projects: [],
        other: {
          industry: null,
          dateCtrtBack: null,
          busiJust: null
        },
        eoas: []
      },
      comments: {
        pop: {
          approval: null,
          rejection: null
        },
        card: {
          review: {
            to: null,
            content: null,
            files: []
          }
        }
      },
      history: [],
      wbsProjInTable: []
    }
  },
  computed:{
    // vuex
    ...mapState('app', {
      windowWidth: state => state.windowSize.width,
      windowHeight: state => state.windowSize.height
    }),
    ...mapGetters('app', [
      'isMobile'
    ]),
    ...mapGetters('user', [
      'loginStatus',
      'userEmail'
    ]),

    // params
    section_color () {
      return section_color['wbs']
    },
    token () {
      return this.$route.params.token
    },
    stageStep () {
      return this.stageListStepper.findIndex(el => el.code === this.wbs.stage.code)
    },
    commentBoxRows () {
      return parseInt((this.windowHeight - 64 - 45) / 18 / 2)
    },

    // wbs check    
    isIndustryValid () {
      return ((this.industries.indexOf(this.wbs.other.industry) > -1) && !!this.wbs.other.industry)
    },

    // wbs stage
    isNewWbs () {
      return this.token === 'new'
    },
    isWbsRecalled () {
      return this.wbs.stage.code == 6
    },
    isWbsRejected () {
      return this.wbs.stage.code == 7
    },
    isWbsPending () {
      return this.isNewWbs || this.isWbsRecalled || this.isWbsRejected
    },
    isWbsInIndustryApproval () {
      return this.wbs.stage.code == 1
    },
    isWbsInCiApproval () {
      return this.wbs.stage.code == 2
    },
    isWbsInFinanceApproval () {
      return this.wbs.stage.code == 3
    },
    isWbsApproved () {
      return [4, 5].indexOf(this.wbs.stage.code) > -1
    },
    isWbsInAnyApproval () {
      return this.isWbsInIndustryApproval || this.isWbsInCiApproval || this.isWbsInFinanceApproval
    },
    isWbsCodeShown () {
      return this.isWbsApproved
    },
    isWbsRecallable () {
      return this.isWbsInAnyApproval
    },

    // user type
    isRequestor () {
      return this.roles.indexOf('Requestor') > -1
    },
    isCollaborator () {
      return this.roles.indexOf('Collaborator') > -1
    },
    isApprover () {
      return this.roles.indexOf('Approver') > -1
    },
    isEditor () {
      return this.roles.indexOf('Editor') > -1
    },
    isReviewer () {
      return this.roles.indexOf('Reviewer') > -1
    },
    // view of previous stage
    isIndustyPrevViewer () {
      return this.roles.indexOf('Viewer-PreIn') > -1
    },
    isCiPrevViewer () {
      return this.roles.indexOf('Viewer-PreCi') > -1
    },
    isFinancePrevViewer () {
      return this.roles.indexOf('Viewer-PreFi') > -1
    },
    isPrevViewer () {
      return this.isIndustyPrevViewer
        || this.isCiPrevViewer
        || this.isFinancePrevViewer
    },
    isViewer () {
      return this.roles.indexOf('Viewer') > -1
    },
    canView () {
      return this.isRequestor
        || this.isCollaborator
        || this.isApprover
        || this.isEditor
        || this.isReviewer
        || this.isPrevViewer
        || this.isViewer
    },
    canShare () {
      return this.isRequestor 
        || this.isCollaborator
        || this.isApprover
        || this.isEditor
    },
    canRecall () {
      return this.isWbsRecallable
        && (
          this.isRequestor 
          || this.isCollaborator
        )
    },

    // user + wbs status
    isWbsUserEditable () {
      return this.isWbsPending && (this.isRequestor || this.isCollaborator)
    },
    isWbsUserApprovable () {
      return this.isWbsInAnyApproval && this.isApprover
    },
    isWbsUserRejectable () {
      return this.isWbsInAnyApproval && this.isApprover
    },
    isWbsCodeUserEditable () {
      return this.isWbsApproved && this.isEditor
    },
    isWbsUserCommentable () {
      return (this.isWbsInAnyApproval && (
          this.isApprover
          || this.isRequestor
          || this.isCollaborator
          || this.isPrevViewer
          || this.isReviewer
        )) 
        || (this.isWbsApproved && (
          this.isEditor
          || this.isRequestor
          || this.isCollaborator
          || this.isPrevViewer
          || this.isReviewer
        )) 
    },

    // menu/list
    stageList() {
      return [
        {
          code: 0,
          value: 'Requesor Drafting',
          inStepper: [0].indexOf(this.wbs.stage.code) > -1
        },
        {
          value: 'Requesor Drafted',
          inStepper: [1,2,3,4,5,6,7,8].indexOf(this.wbs.stage.code) > -1
        },
        {
          code: 6,
          value: 'Requesor Recalled',
          inStepper: [6].indexOf(this.wbs.stage.code) > -1
        },
        {
          code: 7,
          value: 'Requesor Rejected',
          inStepper: [7].indexOf(this.wbs.stage.code) > -1
        },
        {
          code: 8,
          value: 'WBS Parent Abandoned',
          inStepper: [8].indexOf(this.wbs.stage.code) > -1
        },
        {
          code: 1,
          value: 'Industry Leader Approval',
          inStepper: [1,2,3,4,5].indexOf(this.wbs.stage.code) > -1
        },
        {
          code: 2,
          value: 'CI Leader Approval',
          inStepper: [1,2,3,4,5].indexOf(this.wbs.stage.code) > -1
        },
        {
          code: 3,
          value: 'Finance Approval',
          inStepper: [1,2,3,4,5].indexOf(this.wbs.stage.code) > -1
        },
        {
          code: 4,
          value: 'WBS Parent Pending',
          inStepper: [1,2,3,4,5].indexOf(this.wbs.stage.code) > -1
        },
        {
          code: 5,
          value: 'WBS Parent Assigned',
          inStepper: [1,2,3,4,5].indexOf(this.wbs.stage.code) > -1
        }
      ]
    },
    stageListStepper() {
      return this.stageList.filter(el => !!el.inStepper)
    },
    industriesInSelect () {
      if (this.isIndustryValid && !this.isWbsUserEditable) {
        return [this.wbs.other.industry, ...this.industries]
      } else {
        return this.industries
      }
    },
    listApproverCommentTo () {
      return [this.defaultRequestors].concat(this.reviewers)
    },

    // value
    wbsOppStageAndProbText () {
      if (!this.wbs.opp.stage) return null
      // if (!this.wbs.opp.stage || !this.wbs.opp.prob) return null
      let stage = this.wbs.opp.stage || "-"
      return stage
      // let prob = parseFloat(this.wbs.opp.prob) ? parseFloat(this.wbs.opp.prob) + '%' : "-"
      // return [stage, prob].join(' / ')
    }
  },
  methods: {
    // vuex
    ...mapActions('app', ['setMainTitle']),

    // actions
    async searchOpp () {
      if (!!this.inputOppId) {
        this.isOppLoading = true
        this.wbs.opp.id = this.inputOppId
        try {
          const res = await xPost(
            'opportunity',
            'getLightOppById',
            {
              loginStatus: this.loginStatus,
              id: this.inputOppId
            }
          )
          if (res.data.status === 200) {
            if (this.isOppLoading) {
              let opps = res.data.opps || []

              this.cntOpp = opps.length
              if (this.cntOpp == 1) {
                let item = opps[0]
                this.wbs.opp.name = item.name
                this.wbs.opp.accName = item.accName
                this.wbs.opp.sap = item.sap
                this.wbs.opp.stage = item.stage
                this.wbs.opp.prob = item.probability
                this.wbs.opp.amount = parseFloat(item.amount) || 0
                this.wbs.opp.currency = item.currency

                if (this.industries.indexOf(item.industry) > -1) {
                  this.wbs.other.industry = item.industry
                }
                
                if (item.projects instanceof Array) {
                  this.cntProj = item.projects.length
                  this.wbs.projects = item.projects
                    .filter(el => el.practice == 'CI')
                    .map(el => {
                      let c2r = JSON.parse(el.c2r)
                      return {
                        id: el.id,
                        fldMthd: el.fldMthd,
                        ql_o_qt: el.ql_o_qt,
                        sDate: el.sDate ? formatTime(new Date(el.sDate), 'Y-M-D') : null,
                        eDate: el.eDate ? formatTime(new Date(el.eDate), 'Y-M-D') : null,
                        currency: el.currency,
                        amount: el.amount || 0,
                        c2r: {
                          res: (c2r && c2r.status == 1) ? c2r.result || null : null,
                          tag: (c2r && c2r.status == 1) ? c2r.tag || null : null,
                          per: (c2r && c2r.status == 1) ? c2r.tagC2R || null : null,
                        }
                      }
                    })
                    // .filter(el => !!el.id && !!el.c2r.res && !!el.c2r.tag)
                }
              } else {
                throw ''
              }
            }
          } else {
            try {
              if (res.data.message === 'Authorize Failed') {
                this.$router.push('/login')
                return []
              }
            } catch (e) {}
            throw ''
          }
        } catch (err) {
          this.clearOpp()
          console.log('Error [get project]')
          // console.log(err)
        }
        this.checkOpp()
        this.checkProject()
        this.clearOthErr()
        this.isOppLoading = false
      }
    },
    deleteEoA (data) {
      this.wbs.eoas.splice(data.index, 1)
    },
    deleteAttachment (data) {
      this.comments.card.review.files.splice(data.index, 1)
    },
    async startSubmit () {
      let chkOpp = this.checkOpp() 
      let chkProj = this.checkProject() 
      let chkOth = this.checkOther()
      if (!(chkOpp && chkProj && chkOth)) return

      if (this.isNewWbs) {
        this.submit()
      } else {
        this.resubmit()
      }
    },
    async submit () {
      // submit
      this.isSubmitting = true
      let snackbarParam = { type: 'error', text: 'Fail to submit' }
      this.$eventBus.$emit('snackbar', { type: 'loading', text: 'Submitting' })
      try {
        const res = await xPost(
          'wbs',
          'submit',
          {
            loginStatus: this.loginStatus,
            wbs: this.wbs
          }
        )
        if (res.data.status === 200 && res.data.history) {
          this.$router.push('/ci/wbs/details/' + res.data.token)
          this.getWbsInfo(res.data.token)

          snackbarParam = { type: 'success', text: 'Request Submitted' }
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return []
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [submit wbs]')
        // console.log(err)
      }
      this.$eventBus.$emit('snackbar', snackbarParam)
      this.isSubmitting = false
    },
    async resubmit () {
      // submit
      this.isSubmitting = true
      let snackbarParam = { type: 'error', text: 'Fail to submit' }
      this.$eventBus.$emit('snackbar', { type: 'loading', text: 'Submitting' })
      try {
        const res = await xPost(
          'wbs',
          'resubmit',
          {
            loginStatus: this.loginStatus,
            token: this.token,
            wbs: this.wbs,
            stage: this.wbs.stage.code
          }
        )
        if (res.data.status === 200 && res.data.history) {
          this.getWbsInfo()
          snackbarParam = { type: 'success', text: 'Request Submitted' }
        } else if (res.data.err == 'stage') {
          this.getWbsInfo()
          snackbarParam = { type: 'error', text: 'Fail to submit - Stage of request has been changed.' }
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return []
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [resubmit wbs]')
        // console.log(err)
      }
      this.$eventBus.$emit('snackbar', snackbarParam)
      this.isSubmitting = false
    },
    async approve () {
      this.isApprovalConfirming = false
      this.$eventBus.$emit('snackbar', { type: 'loading', text: 'Approving' })

      let action = 0      
      switch (this.wbs.stage.code) {
        case 1:
          action = 2
          break;
      
        case 2:
          action = 5
          break;
      
        case 3:
          action = 7
          break;
      
        default:
          break;
      }

      if (action) {
        let result = await this.proceed(action, this.comments.pop.approval)
        this.comments.pop.approval = ''
        if (result.complete) {
          this.$eventBus.$emit('snackbar', { type: 'success', text: 'Request Approved' })
          this.getWbsInfo()
          return
        } else if (result.err == 'stage') {
          this.$eventBus.$emit('snackbar', { type: 'error', text: 'Fail to approve - Stage of request has been changed.' })
          this.getWbsInfo()
          return
        }
      }

      this.$eventBus.$emit('snackbar', { type: 'error', text: 'Fail to approve' })
    },
    async reject () {
      this.isApprovalRejecting = false
      this.$eventBus.$emit('snackbar', { type: 'loading', text: 'Rejecting' })

      let action = 0      
      switch (this.wbs.stage.code) {
        case 1:
          action = 16
          break;
      
        case 2:
          action = 17
          break;
      
        case 3:
          action = 18
          break;
      
        default:
          break;
      }

      if (action) {
        let result = await this.proceed(action, this.comments.pop.rejection)
        this.comments.pop.rejection = ''
        if (result.complete) {
          this.$eventBus.$emit('snackbar', { type: 'success', text: 'Request Rejected' })
          this.getWbsInfo()
          return
        } else if (result.err == 'stage') {
          this.$eventBus.$emit('snackbar', { type: 'error', text: 'Fail to reject - Stage of request has been changed.' })
          this.getWbsInfo()
          return
        }
      }

      this.$eventBus.$emit('snackbar', { type: 'error', text: 'Fail to reject' })
    },
    async recall () {
      this.isRecalling = false
      this.$eventBus.$emit('snackbar', { type: 'loading', text: 'Recalling' })

      let result = await this.proceed(4)
      if (result.complete) {
        this.$eventBus.$emit('snackbar', { type: 'success', text: 'Request Recalled' })
        this.getWbsInfo()
      } else if (result.err == 'stage') {
        this.$eventBus.$emit('snackbar', { type: 'error', text: 'Fail to recall - Stage of request has been changed.' })
        this.getWbsInfo()
        return
      } else {
        this.$eventBus.$emit('snackbar', { type: 'error', text: 'Fail to recall' })
      }
    },
    async proceed (action, comments) {
      action = action || null
      comments = comments || null

      let result = {
        complete: false,
        details: null
      }

      try {
        if (!action) throw ''
        const res = await xPost(
          'wbs',
          'proceed',
          {
            loginStatus: this.loginStatus,
            token: this.token,
            action,
            comments,
            stage: this.wbs.stage.code
          }
        )
        if (res.data.status === 200) {
          result.complete = true
        } else if (res.data.err == 'stage') {
          result.err = 'stage'
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return []
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [proceed]: ')
        // console.log(err)
      }

      return result
    },
    async saveWbsCode() {
      try {
        this.isWbsCodeEditing = false
        this.isWbsCodeSaving = true
        this.$eventBus.$emit('snackbar', { type: 'loading', text: 'WBS Parent Saving' })
        const res = await xPost(
          'wbs',
          'savecode',
          {
            loginStatus: this.loginStatus,
            token: this.token,
            code: this.inputWbsCode,
            stage: this.wbs.stage.code
          }
        )
        if (res.data.status === 200) {
          this.$eventBus.$emit('snackbar', { type: 'success', text: 'WBS Parent Saved' })
          this.wbs.code = this.inputWbsCode
          this.getWbsInfo()
        } else if (res.data.err == 'stage') {
          this.getWbsInfo()
          this.$eventBus.$emit('snackbar', { type: 'error', text: 'Fail to save WBS Parent - Stage of request has been changed.' })
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return []
            }
          } catch (e) {}
          this.inputWbsCode = this.wbs.code
          throw ''
        }
      } catch (err) {
        console.log('Error [proceed]: ')
        // console.log(err)
        this.$eventBus.$emit('snackbar', { type: 'error', text: 'Fail to save WBS Parent' })
      }
      this.isWbsCodeSaving = false
    },
    async startShareAccess () {
      try {
        const res = await xPost(
          'wbs',
          'getcollaborator',
          {
            loginStatus: this.loginStatus,
            token: this.token
          }
        )
        if (res.data.status === 200 && res.data.data) {
          this.collaborators = res.data.data.map(el => ({
            email: el.Email,
            name: el.Name,
            icon: el.Icon
          }))
          this.isAccessSharing = true
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return []
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [submit wbs]')
        // console.log(err)
      }
    },
    async updateAccess (collaborators) {      
      let snackbarParams = { text: 'Fail to save access', type: 'error' }
      try {
        if (!collaborators) {
          throw ''
        }
          
        let diff = !!this.collaborators.find(el1 => {
            return !collaborators.find(el2 => el1.email == el2.email)
          }) || !!collaborators.find(el1 => {
            return !this.collaborators.find(el2 => el1.email == el2.email)
          })

        if (!diff) {
          this.$eventBus.$emit('snackbar', { text: 'Change not found', type: 'warning' })
          return
        }

        this.$eventBus.$emit('snackbar', { text: 'Saving Access', type: 'loading' })
        this.isAccessSharing = false
        const res = await xPost(
          'wbs',
          'setcollaborator',
          {
            loginStatus: this.loginStatus,
            token: this.token,
            collaborators
          }
        )
        if (res.data.status === 200) {
          snackbarParams = { text: 'Access Saved', type: 'success' }
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return []
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [share access]')
        // console.log(err)
      }
      this.$eventBus.$emit('snackbar', snackbarParams)
    },
    async forward (info) {
      let snackbarParams = { text: 'Fail to forward', type: 'error' }
      try {
        this.$eventBus.$emit('snackbar', { text: 'Forwarding', type: 'loading' })
        this.isForwarding = false
        const res = await xPost(
          'wbs',
          'forward',
          {
            loginStatus: this.loginStatus,
            token: this.token,
            receivers: info.to,
            comments: info.comments,
            stage: this.wbs.stage.code
          }
        )
        if (res.data.status === 200) {
          snackbarParams = { type: 'success', text: 'Forwarded successfully' }
          this.getWbsInfo()
        } else if (res.data.err == 'stage') {
          this.getWbsInfo()
          snackbarParams = { type: 'error', text: 'Fail to forward - Stage of request has been changed.' }
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return []
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [forward]')
        // console.log(err)
      }
      this.$eventBus.$emit('snackbar', snackbarParams)
    },
    async startComments () {
      let toStart = false
      try {
        const res = await xPost(
          'wbs',
          'getreviewer',
          {
            loginStatus: this.loginStatus,
            token: this.token
          }
        )
        if (res.data.status === 200 && res.data.data) {
          this.reviewers = res.data.data.map(el => ({
            email: el.Email,
            name: el.Name,
            icon: el.Icon
          }))
          toStart = true
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return []
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [get reviewers wbs]')
        // console.log(err)
      }
      if (toStart) {
        if (this.$refs['contract-comment-form']) {
          this.$refs['contract-comment-form'].resetValidation()
        }
        this.resetComments()
        this.isCommenting = true
      }
    },
    async sendComments () {
      if (!this.$refs['contract-comment-form'].validate()) return
      
      let snackbarParams = { text: 'Fail to send comments', type: 'error' }
      try {
        this.$eventBus.$emit('snackbar', { text: 'Sending Comments', type: 'loading' })
        let role = ''
        if (this.isApprover) {
          role = 'Approver'
        } else if (this.isEditor) {
          role = 'Editor'
        } else if (this.isFinancePrevViewer) {
          role = 'FinPrevViewer'
        } else if (this.isCiPrevViewer) {
          role = 'CiPrevViewer'
        } else if (this.isIndustyPrevViewer) {
          role = 'IndtPrevViewer'
        } else if (this.isReviewer) {
          role = 'Reviewer'
        } else if (this.isRequestor || this.isCollaborator) {
          role = 'Requestor'
        }
        let receiver = this.comments.card.review.to ?
          this.comments.card.review.to.email.indexOf('@') > -1 ?
            this.comments.card.review.to.email
            : ''
          : ''
        const res = await xPost(
          'wbs',
          'comment',
          {
            loginStatus: this.loginStatus,
            token: this.token,
            receiver,
            comments: this.comments.card.review.content,
            attach: this.comments.card.review.files,
            stage: this.wbs.stage.code,
            role
          }
        )
        if (res.data.status === 200) {
          snackbarParams = { text: 'Comments Sent', type: 'success' }
          this.getWbsInfo()
          this.isCommenting = false
        } else if (res.data.err == 'stage') {
          this.getWbsInfo()
          snackbarParams = { type: 'error', text: 'Fail to send comments - Stage of request has been changed.' }
          this.isCommenting = false
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return []
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [forward]')
        // console.log(err)
      }
      this.$eventBus.$emit('snackbar', snackbarParams)
    },
    async abandon () {
      let snackbarParams = { text: 'Fail to abandon WBS request', type: 'error' }
      try {
        this.$eventBus.$emit('snackbar', { text: 'Abandoning', type: 'loading' })
        this.isAbandoning = false
        
        const res_abandon = await this.proceed(35, '')
        if (res_abandon.complete) {
          snackbarParams = { text: 'WBS Request Abandoned', type: 'success' }
          this.$router.push('/ci/wbs/list')
        } else if (res_abandon.err == 'stage') {
          this.$eventBus.$emit('snackbar', { type: 'error', text: 'Fail to abandon WBS request - Stage of request has been changed.' })
          this.getWbsInfo()
          return
        }
      } catch (err) {
        console.log('Error [abandon]')
        // console.log(err)
      }
      this.$eventBus.$emit('snackbar', snackbarParams)
    },

    // functions
    async getIndustry () {
      try {
        const res = await xPost(
          'normal',
          'industry',
          {
            loginStatus: this.loginStatus
          }
        )
        if (res.data.status === 200) {
          const data = res.data.data || []
          this.industries = data.map(el => el.Industry)
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return []
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [get industries]')
        // console.log(err)
      }
    },
    async getWbsInfo (token, loading) {
      
      loading = loading || false
      token = token || this.token || ''
      if (loading) this.isPageLoading = true
      if (token !== this.token) {
        if (token) {
          this.$router.push('/ci/wbs/details/' + token)
        } else {
          this.router.push('/ci/wbs/list')
        }
      }

      try {
        const res = await xPost(
          'wbs',
          'wbsinfo',
          {
            loginStatus: this.loginStatus,
            token
          }
        )
        if (res.data.status === 200) {
          let wbs = res.data.wbs
          let projects = res.data.projects
          let eoas = res.data.eoas
          let history = res.data.history

          this.roles = res.data.my_roles

          this.wbs.reqid = res.data.reqid

          this.wbs.stage.code = history.length ? parseInt(history[0].StageCode) : null
          if (!this.wbs.stage.code) throw 'stage'
          
          this.requestor.email = wbs.RequestorEmail
          this.requestor.name = wbs.RequestorName
          this.requestor.icon = wbs.RequestorIcon

          this.wbs.code = wbs.WbsCode
          
          this.inputOppId = wbs.OppId
          this.wbs.opp.id = wbs.OppId
          this.wbs.opp.name = wbs.OppName
          this.wbs.opp.accName = wbs.AccName
          this.wbs.opp.sap = wbs.SoldToParty
          this.wbs.opp.stage = wbs.Stage
          this.wbs.opp.prob = parseFloat(wbs.Prob)
          this.wbs.opp.amount = parseFloat(wbs.OppAmount)
          this.wbs.opp.currency = wbs.OppCurrency

          this.wbs.projects = projects.map(el => ({
            id: el.ProjectId,
            c2r: {
              res: !!el.C2rPerc || (el.C2rPerc == 0),
              tag: !!el.C2rPerc || (el.C2rPerc == 0),
              per: el.C2rPerc
            },
            fldMthd: el.FieldMthd,
            ql_o_qt: el.QloQt,
            sDate: el.SDate,
            eDate: el.EDate,
            currency: el.Currency,
            amount: parseFloat(el.ProjectAmount)
          }))

          if (!this.isWbsUserEditable || (this.industries.indexOf(wbs.Industry) > -1)) {
            this.wbs.other.industry = wbs.Industry
          }
          
          this.wbs.other.dateCtrtBack = wbs.DateCtrtBack
          this.wbs.other.busiJust = wbs.BusiJust

          this.wbs.eoas = eoas.map(el => {
            try {
              return  {
                DispName: el.DispName,
                FileName: el.FileName,
                UploadTime: el.UploadTime,
                delete: true,
                download: true,
                name: el.DispName,
                time: el.UploadTime,
                token: this.token,
                url: el.FileName
              }
            } catch (error) {
              return {
                error_file: true
              }
            }
          })

          this.setHistory(history)
          this.cntOpp = 1
          this.cntProj = this.wbs.projects.length

          this.isPageLoading = false
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return []
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [get wbs info]')
        // console.log(err)
        this.$router.push('/ci/wbs/list')
      }
    },
    focusSearch () {
      this.isSearchFocused = true
    },
    blurSearch () {
      this.isSearchFocused = false
      this.resetInputOppId()
    },
    changeSearch () {
      if (this.isSearchFocused && this.inputOppId) {
        this.searchOpp()
      }
    },
    resetInputOppId () {
      this.inputOppId = this.wbs.opp.id
    },
    checkOpp () {
      this.clearOppErr()
      if (!this.wbs.opp.id) {
        this.setErr('add', 'opp', 'NoID')
        return false
      }
      if (this.cntOpp == 0) {
        this.setErr('add', 'opp', 'NotFound')
        return false
      }
      if (this.cntOpp > 1) {
        this.setErr('add', 'opp', 'MoreThan1')
        return false
      }
      if (!this.checkOppStage()) {
        this.setErr('add', 'opp', 'ErrStage')
        return false
      }
      return true
    },
    checkOppStage() {
      return (["Negotiating", "Negotiation", "Closed Won - In Review", "Closed Won - Approved"].indexOf(this.wbs.opp.stage) > -1)
    },
    checkProject () {
      this.clearProjErr()
      // if (this.cntOpp && (this.wbs.projects.length == 0)) {
      //   this.setErr('add', 'project', 'NotFound')
      //   return false
      // }
      if (this.wbs.projects.length == 0) {
        this.setErr('add', 'project', 'CINotFound')
        return false
      }

      let result = true
      result = this.checkC2R()
      return result
    },
    checkC2R () {
      // let c2r_tt = 0
      // this.wbs.projects.forEach(el => {
      //   let amount = el.amount || 0
      //   c2r_tt += (el.c2r && el.c2r.per && parseFloat(el.c2r.per)) ? (parseFloat(el.c2r.per) * amount / 100) : 0
      // });      
      // if (c2r_tt == 0) {
      //   this.setErr('add', 'project', 'NoApprovalTag')
      //   return false
      // }
      let result = true

      let chk_c2r = true
      this.wbs.projects.forEach(el => {
        if (!el.c2r || !el.c2r.res || !el.c2r.tag) {
          this.setErr('add', 'project', 'NoApprovalTag')
          chk_c2r = false
        }
      })
      result = result && chk_c2r
      return result
    },
    checkOther () {
      this.clearOthErr()
      let result = true
      if (!this.wbs.other.industry) {
        this.setErr('add', 'other', 'NoIndustry')
        result = false
      }
      if (!this.wbs.other.dateCtrtBack) {
        this.setErr('add', 'other', 'NoDateCtrtBack')
        result = false
      }
      if (!this.wbs.other.busiJust) {
        this.setErr('add', 'other', 'NoBusiJust')
        result = false
      }
      if (!this.wbs.eoas.length) {
        this.setErr('add', 'other', 'NoEoA')
        result = false
      }
      return result
    },
    clearOpp() {
      this.wbs.opp.name = null
      this.wbs.opp.accName = null
      this.wbs.opp.sap = null
      this.wbs.opp.stage = null
      this.wbs.opp.prob = null
      this.wbs.opp.amount = null
      this.wbs.opp.currency = null
      this.cntOpp = 0
      this.clearProject()
    },
    clearProject () {
      this.wbs.projects = []
      this.cntProj = 0
    },
    clearOppErr () {
      this.error.opp.forEach(el => {
        el.valid = false
      });
    },
    clearProjErr () {
      this.error.project.forEach(el => {
        el.valid = false
      });
    },
    clearOthErr () {
      this.error.other.forEach(el => {
        el.valid = false
      });
    },
    setHistory (history) {
      this.history = []
      history.forEach(el => {
        if (!parseInt(el.ToDisplay)) return
        let index = this.history
          .findIndex(el2 =>
            el2.user.email === el.User &&
            el2.time === el.CreatedTime &&
            el2.action === el.ActionName &&
            el2.comment === el.Content
          )
        if (index < 0) {
          index = this.history.length
          this.history.push({
            user: {
              email: el.User || '',
              name: el.UserName || '',
              icon: el.UserIcon || ''
            },
            time: el.CreatedTime,
            action: el.ActionName,
            comment: el.Content,
            files: [],
            receivers: []
          })
        }
        if (!!el.FileName && !!el.DispName) {
          this.history[index].files.push({
            url: el.FileName,
            name: el.DispName
          })
        }
        if (!!el.ToUser) {
          this.history[index].receivers.push({
            email: el.ToUser || '',
            name: el.ToUserName || '',
            icon: el.ToUserIcon || ''
          })
        }
      })
    },
    setErr(action, err, key) {
      if (!(this.error[err] && this.error[err] instanceof Object)) return
      this.error[err].forEach(el => {
        if (el.key == key) {
          switch (action) {
            case 'add':
              el.valid = true
              break;

            case 'remove':
            case 'rem':
              el.valid = false
              break;
          
            default:
              break;
          }
        }
      });
    },
    resetComments () {
      this.comments.card.review.to = this.listApproverCommentTo.length === 1 ? this.listApproverCommentTo[0] : null
      this.comments.card.review.content = null
      this.comments.card.review.files = []
    },
    closeSendComments() {
      this.isCommenting = false
    },

    // upload/download files
    beforeUploadEoA(file) {
      this.cntFileUploading++
    },
    errorUploadEoA(err, file) {
      console.log('Error [upload eoa]')
      if (this.cntFileUploading > 0) this.cntFileUploading--
    },
    successUploadEoA(res, file) {
      if (res.status === 200) {
        const data = res.detail[0]
        const date = new Date()
        const time = date.getFullYear() + '-' + this.add0(date.getMonth() + 1) + '-' + this.add0(date.getDate()) + ' ' + this.add0(date.getHours()) + ':' + this.add0(date.getMinutes()) + ':' + this.add0(date.getSeconds())
        this.wbs.eoas.push({
          url: data.url,
          name: file.name,
          time: time,
          download: true,
          delete: true,
          DispName: file.name,
          FileName: data.url,
          UploadTime: time,
          token: this.token
        })
        // this.$eventBus.$emit('refreshGapi')
      } else {
        console.log('Error [upload eoa]')
        this.$eventBus.$emit('snackbar', { text: 'File is not acceptable', type: 'error' })
        if (res.message === 'Authorize Failed')
          this.$router.push('/login')
      }
      if (this.cntFileUploading > 0) this.cntFileUploading--
    },
    beforeUploadCmmtAtt(file) {
      // this.cntFileUploading++
    },
    errorUploadCmmtAtt(err, file) {
      console.log('Error [upload comments attachment]')
      // if (this.cntFileUploading > 0) this.cntFileUploading--
    },
    successUploadCmmtAtt(res, file) {
      if (res.status === 200) {
        const data = res.detail[0]
        const date = new Date()
        const time = date.getFullYear() + '-' + this.add0(date.getMonth() + 1) + '-' + this.add0(date.getDate()) + ' ' + this.add0(date.getHours()) + ':' + this.add0(date.getMinutes()) + ':' + this.add0(date.getSeconds())
        this.comments.card.review.files.push({
          url: data.url,
          name: file.name,
          time: time,
          download: true,
          delete: true,
          DispName: file.name,
          FileName: data.url,
          UploadTime: time,
          token: this.token
        })
        // this.$eventBus.$emit('refreshGapi')
      } else {
        console.log('Error [upload comments attachment]')
        this.$eventBus.$emit('snackbar', { text: 'File is not acceptable', type: 'error' })
        if (res.message === 'Authorize Failed')
          this.$router.push('/login')
      }
      // if (this.cntFileUploading > 0) this.cntFileUploading--
    },
    async downloadFile (data) {
      try {
        const res = await xDownload(
          data.item.url,
          data.item.name,
          { 
            loginStatus: this.loginStatus
          }
        )
        try {
          if (res.data.message === 'Authorize Failed') {
            this.$router.push('/login')
            return
          }
        } catch (e) {throw ''}
      } catch (e) {
        console.log('Error [download file]')
        // console.log(e)
      }
    },

    // tool
    getItemIfUnique (arr) {
      let new_arr = [... new Set(arr)]
      return new_arr.length == 1 ? new_arr[0] : null
    },
    add0 (m) {
      return m < 10 ? '0' + m : m
    },
    toThousandsInt (n) {
      return toThousands(Math.round(n))
    },

    // init
    async init () {
      if (this.isNewWbs) {
        this.$router.push({path: '/ci/wbs/retired'})
        // let get_industry = await this.getIndustry()
        // this.wbs.stage.code = 0
        // this.roles = ['Requestor']
        // this.isPageLoading = false
      } else {
        let get_industry = this.getIndustry()
        let get_wbs = this.getWbsInfo()
        await get_industry
        await get_wbs
        this.$eventBus.$emit('clearNotification', {
          type: 'WBS Request Detail',
          token: this.token
        })
        if (!this.canView) this.$router.push('/ci/wbs')
      }
    }
  },
  watch: {
    token (val, oVal) {
      if (val !== oVal) {
        this.getWbsInfo(val, true)
      }
    },
    'wbs.code': {
      deep: true,
      handler (val, oVal) {
        if (val != oVal) {
          this.inputWbsCode = val;
        }
      }
    },
    'wbs.projects': {
      deep: true,
      handler (val) {
        let temp = [...val]
        if (temp.length) {
          let amount_tt = 0
          let currency = ''
          let c2r_tt = 0
          let c2r_tt_val = false
          temp.forEach(el => {
            let amount = parseFloat(el.amount) || 0
            amount_tt += amount
            currency = el.currency || 0
            let c2r = (el.c2r && el.c2r.per && parseFloat(el.c2r.per)) ? (parseFloat(el.c2r.per) * amount / 100) : null
            if (c2r) {
              c2r_tt_val = true
              c2r_tt += c2r
            }
            });
          let c2r_per_tt = (c2r_tt_val && amount_tt) ? to2Digits(c2r_tt * 100 / amount_tt) + '%' : '-'
          temp.push({
            total: true,
            currency,
            amount: to2Digits(amount_tt),
            c2r: c2r_per_tt
          })
        }
        this.wbsProjInTable = temp
      }
    }
  },
  async created () {
    this.setMainTitle('WBS Request')
    this.init()
  }
}
</script>

<style lang="sass">
  .wbs-detail
    width: 100% !important

    button
      margin-top: 3px
      margin-bottom: 3px
    

    .wbs-detail-loading
      width: 100% !important
      text-align: center
      padding-top: calc(50vh - 85px)
    

    .wbs-stepper
      .v-stepper,
      .v-stepper--vertical
        background: none
        box-shadow: none
        min-height: 0
        margin: 0 -12px
        padding-bottom: 0

        .v-stepper__header
          height: auto
        

        .v-stepper__step
          padding: 0 24px 3px 24px
        

        .v-stepper__header .v-divider
          border-color: hsla(0,0%,100%,.5)
        

        .v-stepper__content
          height: 10px
        
      
    

    .v-card-x-buttons
      text-align: left
    

    .v-card-x-layout
      .v-card-x-title
        font-size: 14px
        padding-left: 9px !important
        padding-right: 9px !important
        padding-bottom: 6px !important
      

      .v-card-x-cell
        text-align: left

        .v-card-x-cell-title
          font-size: 14px
          color: rgba(0,0,0,1)
          transform: translateX(-12.5%) scale(.75)
          -webkit-transform: translateX(-12.5%) scale(.75)
          width: 132%

        .v-card-x-cell-title-absolute
          position: absolute
        
      

      .v-card-wbs-amount
        .v-label
          font-size: 14px
        

        // .v-messages__message
        //   color: rgba(0, 0, 255, 0.5)
        // 
      
    
  


  .v-flex-row-breaker
    padding: 0 !important
  

  .wbs-radio-group-horizonal
    .v-input__control
      width: 100% !important

      .v-radio
        width: calc(50% - 16px) !important
        align-items: start

        label
          top: 2px
        
      
    
  

  .wbs-radio-group-vertical
    .v-input__control
      width: 100% !important

      .v-radio
        width: 100% !important
        align-items: start

        label
          top: 2px
        
      
    

    .v-input--selection-controls
      margin-top: 0
    
  

  .bottom-btn-container
    text-align: left

    &-right
      text-align: right
    

    .v-btn,
    &-right .v-btn
      max-width: 130px
      min-width: 130px
    
  

  .wbs-radio-group-vertical
    .v-input__control
      width: 100% !important

      .v-radio
        width: 100% !important
        align-items: start

        label
          top: 2px
        
      
    

    .v-input--selection-controls
      margin-top: 0
    
  

  .bottom-btn-container
    text-align: left

    &-right
      text-align: right
    

    .v-btn,
    &-right .v-btn
      max-width: 130px
      min-width: 130px
    
  

  .comments-card,
  .confirm-card,
  .approve-card,
  .wbs-preview-card,
  .new-drafted-wbs-card
    width: 600px !important
    max-width: 100% !important
    overflow: hidden
  

  .comments-card,
  .new-drafted-wbs-card
    height: 450px !important
  

  .confirm-card
    width: 400px !important
  

  .approve-card
    width: 500px !important
    height: 286px !important
  

  .wbs-code-card
    width: 320px !important
    height: 223px !important
  

  .wbs-preview-card
    width: 1200px !important
    height: 90vh !important
  

  .wbs-review-comments-container
    .v-input
      padding-top: 0px
    

    .v-input__slot
      padding: 0 9px 9px
    

    .person-select
      .v-input__slot
        padding: 9px
      
      .v-select__selections
        padding-left: 6px
      
    

    textarea
      margin-top: 0px !important
      padding-top: 8px !important
    
  

  .wbs-review-comments-container-icon
    display: flex

    i
      margin: 0 10px 0 0
    

    &-bold
      font-weight: 500
    
  


  .add-opp-card
    width: 900px !important
    max-width: 100% !important
    height: 90vh !important
    min-height: 600px !important
    overflow: hidden
  

  .app-header-title-right
    display: flex
    padding-top: 2px
    margin-right: 12px

    .v-btn
      margin-left: 12px
      margin-right: 0px
    

    @media(max-width:960px)
      .v-btn
        margin-top: 9px
        margin-left: 12px
      
    
  
</style>
